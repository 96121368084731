/**
 * 規格項目 エンティティ
 */
import InputType from '@/models/enums/input-type'
import { isNud } from '@/utilities/typing'

class SpecLabel {
    spec_label_id;
    label_name;
    input_type;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.spec_label_id = properties.spec_label_id;
        this.label_name = properties.label_name;
        this.input_type = properties.input_type;
    }

    get input_type_display() {
        return InputType.get(this.input_type);
    }
}

export default SpecLabel;
