/**
 * 製品画像マスタ エンティティ
 */
import { isNud } from '@/utilities/typing';
import Product from '@/models/entities/product-group';

class ProductImage {
    product_image_id;
    product;
    file_label;
    file_name;
    file_path;
    thumbnail_path;
    mime_type;
    file_size;
    file_url;
    thumbnail_url;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.product_image_id = properties.product_image_id ?? null;
        this.product = new Product(properties.product);
        this.file_label = properties.file_label;
        this.file_name = properties.file_name;
        this.file_path = properties.file_path;
        this.thumbnail_path = properties.thumbnail_path;
        this.mime_type = properties.mime_type;
        this.file_size = properties.file_size;
        this.file_url = properties.file_url;
        this.thumbnail_url = properties.thumbnail_url;
    }
}

export default ProductImage;
