import Enum from './enum'

/**
 * 製品 公開区分
 */
class PublicType extends Enum {
    static EXTERNAL_PUBLIC = 1; // 社外公開
    static IN_COMPANY_PUBLIC = 2; // 社内のみ公開
    static INVALID = 3; // 無効

    static values() {
        return {
            [this.EXTERNAL_PUBLIC]: '社外公開',
            [this.IN_COMPANY_PUBLIC]: '社内のみ公開',
            [this.INVALID]: '無効',
        }
    }

    static short_values() {
        return {
            [this.EXTERNAL_PUBLIC]: '社外',
            [this.IN_COMPANY_PUBLIC]: '社内のみ',
            [this.INVALID]: '無効',
        }
    }

    static getShort(key) {
        const values = this.short_values();

        if (key in values) {
            return values[key];
        }

        return null;
    }
}

export default PublicType;
