/**
 * カタログマスタ エンティティ
 */
import { isNud } from '@/utilities/typing';

class Catalog {
    catalog_id;
    catalog_name;
    priority;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.catalog_id = properties.catalog_id;
        this.catalog_name = properties.catalog_name;
        this.priority = properties.priority;
    }
}

export default Catalog;
