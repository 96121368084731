/**
 * 製品製作図用規格値マスタ エンティティ
 */
import { isNud } from '@/utilities/typing';
import ProductRevision from '@/models/entities/product';
import ProductProductionSpecLabel from '@/models/entities/product-production-spec-label';

class ProductProductionSpecValue {
    product_production_spec_value_id;
    product_production_spec_value_revision_id;
    product;
    production_spec_label;
    spec_value;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        //（製品詳細画面で使用）リビジョンが来る場合があるため
        if (properties.product_production_spec_value_revision_id) {
            this.product_production_spec_value_revision_id = properties.product_production_spec_value_revision_id ?? null;
        } else {
            this.product_production_spec_value_id = properties.product_production_spec_value_id ?? null;
        }
        this.product = new ProductRevision(properties.product);
        this.production_spec_label = new ProductProductionSpecLabel(properties.production_spec_label);
        this.spec_value = properties.spec_value ?? null;
    }
}

export default ProductProductionSpecValue;
