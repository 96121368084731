/**
 * 製品グループ規格変更履歴マスタ エンティティ
 */
import { isNud } from '@/utilities/typing';
import { dateYmdForInputDate } from '@/utilities/date-format';
import ProductGroup from '@/models/entities/product-group';

class ProductGroupWorkflow {
    product_group_workflow_id;
    product_group;
    workflow_date;
    workflow_number;
    approved_status;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.product_group_workflow_id = properties.product_group_workflow_id ?? null;
        this.product_group = new ProductGroup(properties.product_group);
        this.workflow_date = properties.workflow_date ? dateYmdForInputDate(properties.workflow_date) : null;
        this.workflow_number = properties.workflow_number ?? null;
        this.approved_status = properties.approved_status ?? null;
    }
}

export default ProductGroupWorkflow;
