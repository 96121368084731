/**
 * 共有メモ エンティティ
 */
import { isNud } from '@/utilities/typing';
import { dateYmd } from '@/utilities/date-format';
import { dateYmdForInputDate } from '@/utilities/date-format';
import ProductGroup from '@/models/entities/product-group';
import User from '@/models/entities/user';

class ProductNote {
    product_note_id;
    product_group;
    note_date;
    user;
    content;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }
        this.product_note_id = properties.product_note_id ?? null;
        this.product_group = new ProductGroup(properties.product_group) ?? null;
        this.note_date = dateYmdForInputDate(properties.note_date) ?? null;
        this.user = new User(properties.user);
        this.content = properties.content ?? null;
    }

    get note_date_display() {
        return dateYmd(this.note_date);
    }
}

export default ProductNote;
