/**
 * 製品構成品マスタ エンティティ
 */
import { isNud } from '@/utilities/typing';
import Product from '@/models/entities/product';

class ProductComponentRevision {
    product_component_id;
    product;
    child_product;
    sequence;
    component_count;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }
        this.product_component_id = properties.product_component_id ?? null;
        this.product = new Product(properties.product);
        this.child_product = new Product(properties.child_product);
        this.sequence = properties.sequence ?? null;
        this.component_count = properties.component_count ?? null;
    }

    get component_count_display() {
        if (this.component_count) {
            return parseInt(this.component_count, 10).toLocaleString();
        }

        return this.component_count;
    }
}

export default ProductComponentRevision;
