/**
 * 製品商社別情報マスタ エンティティ
 */
import { isNud } from '@/utilities/typing';
import Product from '@/models/entities/product';
import Retailer from '@/models/entities/retailer';

class ProductRetailer {
    product_retailer_id;
    product;
    retailer;
    is_retailer_selling;
    retailer_product_code;
    retailer_product_name;
    retailer_rate;
    retailer_price;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.product_retailer_id = properties.product_retailer_id ?? null;
        this.product = new Product(properties.product);
        this.retailer = new Retailer(properties.retailer);
        this.is_retailer_selling = properties.is_retailer_selling ?? 0;
        this.retailer_product_code = properties.retailer_product_code ?? null;
        this.retailer_product_name = properties.retailer_product_name ?? null;
        this.retailer_rate = properties.retailer_rate ?? null;
        this.retailer_price = properties.retailer_price ?? null;
    }
}

export default ProductRetailer;
