/**
 * 製品グループ エンティティ
 */
import { isNud } from '@/utilities/typing';
import { dateYmd } from '@/utilities/date-format';
import { dateYmdForInputDate } from '@/utilities/date-format';
import ProductGroupWorkflow from '@/models/entities/product-group-workflow';
import ProductGroupImage from '@/models/entities/product-group-image';
import ProductGroupCatalogDrawing from '@/models/entities/product-group-catalog-drawing';
import ProductGroupStandardDrawing from '@/models/entities/product-group-standard-drawing';
import ProductGroupFlyer from '@/models/entities/product-group-flyer';
import ProductGroupMovie from '@/models/entities/product-group-movie';
import ProductGroupCatalogPage from '@/models/entities/product-group-catalog-page';
import ProductSubgroup from '@/models/entities/product-subgroup';
import ProductNote from '@/models/entities/product-note';
import PublishedStatus from '@/models/enums/published-status';

class ProductGroup {
    product_group_id;
    product_group_code;
    published_status;
    published_datetime;
    published_revision;
    public_type;
    product_group_name;
    product_group_name_kana;
    product_group_name_en;
    option_name;
    option_name_en;
    description;
    description_en;
    is_on_catalog;
    is_price_on_catalog;
    merged_product_group;
    pending_date;
    supply_type;
    private_note;
    field_1;
    field_2;
    field_3;
    availability;
    updated_date;
    updated_user_name;

    workflows;
    images;
    catalog_drawings;
    standard_drawings;
    flyers;
    movies;
    catalog_pages;
    subgroups;
    notes;

    catalog_page_numbers;
    has_product_group_flyer;
    has_product_group_movie;
    has_product_retailer;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.product_group_id = properties.product_group_id;
        this.product_group_code = properties.product_group_code;
        this.published_status = properties.published_status;
        this.published_datetime = properties.published_datetime;
        this.published_revision = properties.published_revision;
        this.public_type = properties.public_type;
        this.product_group_name = properties.product_group_name;
        this.product_group_name_kana = properties.product_group_name_kana;
        this.product_group_name_en = properties.product_group_name_en;
        this.option_name = properties.option_name;
        this.option_name_en = properties.option_name_en;
        this.description = properties.description;
        this.description_en = properties.description_en;
        this.is_on_catalog = properties.is_on_catalog ?? 0;
        this.is_price_on_catalog = properties.is_price_on_catalog ?? 0;
        this.merged_product_group = properties.merged_product_group;
        this.pending_date = dateYmdForInputDate(properties.pending_date);
        this.supply_type = properties.supply_type;
        this.private_note = properties.private_note;
        this.field_1 = properties.field_1;
        this.field_2 = properties.field_2;
        this.field_3 = properties.field_3;
        this.availability = properties.availability;
        this.updated_date = dateYmd(properties.updated_date);
        this.updated_user_name = properties.updated_user_name;

        this.workflows = [];
        if ('workflows' in properties) {
            for (let workflow of properties.workflows) {
                this.workflows.push(new ProductGroupWorkflow(workflow));
            }
        }
        this.images = [];
        if ('images' in properties) {
            for (let image of properties.images) {
                this.images.push(new ProductGroupImage(image));
            }
        }
        this.catalog_drawings = [];
        if ('catalog_drawings' in properties) {
            for (let catalog_drawing of properties.catalog_drawings) {
                this.catalog_drawings.push(new ProductGroupCatalogDrawing(catalog_drawing));
            }
        }
        this.standard_drawings = [];
        if ('standard_drawings' in properties) {
            for (let standard_drawing of properties.standard_drawings) {
                this.standard_drawings.push(new ProductGroupStandardDrawing(standard_drawing));
            }
        }
        this.flyers = [];
        if ('flyers' in properties) {
            for (let flyer of properties.flyers) {
                this.flyers.push(new ProductGroupFlyer(flyer));
            }
        }
        this.catalog_pages = [];
        if ('catalog_pages' in properties) {
            for (let catalog_page of properties.catalog_pages) {
                this.catalog_pages.push(new ProductGroupCatalogPage(catalog_page));
            }
        }
        this.movies = [];
        if ('movies' in properties) {
            for (let movie of properties.movies) {
                this.movies.push(new ProductGroupMovie(movie));
            }
        }
        this.subgroups = [];
        if ('subgroups' in properties) {
            for (let subgroup of properties.subgroups) {
                this.subgroups.push(new ProductSubgroup(subgroup));
            }
        }
        this.notes = [];
        if ('notes' in properties) {
            for (let note of properties.notes) {
                this.notes.push(new ProductNote(note));
            }
        }

        this.catalog_page_numbers = properties.catalog_page_numbers;
        this.has_product_group_flyer = properties.has_product_group_flyer;
        this.has_product_group_movie = properties.has_product_group_movie;
        this.has_product_retailer = properties.has_product_retailer;
    }

    get latest_published_date() {
        if (this.published_status === PublishedStatus.PUBLISHED) {
            return dateYmd(this.published_datetime);
        }

        return PublishedStatus.get(this.published_status);
    }

    get pending_date_display() {
        if (!this.pending_date) {
            return '';
        }

        return dateYmd(this.pending_date);
    }

    get updated_at_display() {
        return dateYmd(this.updated_at);
    }
}

export default ProductGroup;
