import Enum from './enum'

/**
 * 製品グループ 仕入区分:[1:自社製品 2:仕入品 3:OEM品]
 */
class SupplyType extends Enum {
    static OWN = 1; // 自社製品
    static SUPPLY = 2; // 仕入品
    static OEM = 3; // OEM品

    static values() {
        return {
            [this.OWN]: '自社製品',
            [this.SUPPLY]: '仕入品',
            [this.OEM]: 'OEM品',
        }
    }

    static short_values() {
        return {
            [this.OWN]: '自社',
            [this.SUPPLY]: '仕入',
            [this.OEM]: 'OEM',
        }
    }

    static getShort(key) {
        const values = this.short_values();

        if (key in values) {
            return values[key];
        }

        return null;
    }
}

export default SupplyType;
