/**
 * ユーザ エンティティ
 */
import Role from '@/models/enums/role'
import AccountType from '@/models/enums/account-type'
import IsAvailable from '@/models/enums/is-available'
import { isNud } from '@/utilities/typing'

class User {
    user_id;
    account_type;
    user_name;
    google_user_id;
    google_email;
    login_key;
    is_available;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.user_id = properties.user_id;
        this.account_type = properties.account_type;
        this.user_name = properties.user_name;
        this.google_user_id = properties.google_user_id;
        this.google_email = properties.google_email;
        this.login_key = properties.login_key;
        this.is_available = properties.is_available;
        this.roles = [];
        if ('roles' in properties) {
            this.roles = properties.roles.map(val => parseInt(val, 10));
        }
    }

    get identifier_display() {
        if (this.account_type === AccountType.ID_PASSWORD) {
            return this.login_key;
        } else {
            return this.google_email;
        }
    }

    get roles_display() {
        return Role.join(this.roles);
    }

    get account_type_display() {
        return AccountType.get(this.account_type)
    }

    get is_available_display() {
        return IsAvailable.get(this.is_available)
    }
}

export default User;
