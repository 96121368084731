/**
 * 製品規格図用規格項目マスタ エンティティ
 */
import { isNud } from '@/utilities/typing';
import ProductSubgroup from '@/models/entities/product-subgroup';
import SpecLabel from '@/models/entities/spec-label';
import SpecPrefix from '@/models/entities/spec-prefix';
import SpecSuffix from '@/models/entities/spec-suffix';

class ProductStandardSpecLabel {
    product_standard_spec_label_id;
    product_standard_spec_label_revision_id;
    product_subgroup;
    spec_label;
    spec_prefix;
    spec_suffix;
    priority;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        //（製品詳細画面で使用）リビジョンが来る場合があるため
        if (properties.product_standard_spec_label_revision_id) {
            this.product_standard_spec_label_revision_id = properties.product_standard_spec_label_revision_id ?? null;
        } else {
            this.product_standard_spec_label_id = properties.product_standard_spec_label_id ?? null;
        }
        this.product_subgroup = new ProductSubgroup(properties.product_subgroup);
        this.spec_label = new SpecLabel(properties.spec_label);
        this.spec_prefix = new SpecPrefix(properties.spec_prefix);
        this.spec_suffix = new SpecSuffix(properties.spec_suffix);
        this.priority = properties.priority ?? null;
    }
}

export default ProductStandardSpecLabel;
