/**
 * 商社 エンティティ
 */
import { isNud } from '@/utilities/typing'

class Retailer {
    retailer_id;
    retailer_name;
    priority;
    deleted_timestamp;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.retailer_id = properties.retailer_id;
        this.retailer_name = properties.retailer_name;
        this.priority = properties.priority;
        this.deleted_timestamp = properties.deleted_timestamp;
    }
}

export default Retailer;
