import Enum from './enum'

/**
 * 商社販売フラグ
 */
class IsRetailerSelling extends Enum {
    static YES = 1; // 販売
    static NO = 0; // 販売なし

    static values() {
        return {
            [this.YES]: '販売',
            [this.NO]: '販売なし',
        }
    }
}

export default IsRetailerSelling;
