/**
 * 製品サブグループマスタ エンティティ
 */
import { isNud } from '@/utilities/typing';
import ProductGroup from '@/models/entities/product-group';
import ProductSubgroupImage from '@/models/entities/product-subgroup-image';
import ProductSubgroupCatalogDrawing from '@/models/entities/product-subgroup-catalog-drawing';
import ProductSubgroupStandardDrawing from '@/models/entities/product-subgroup-standard-drawing';
import ProductSubgroupProductionDrawing from '@/models/entities/product-subgroup-production-drawing';
import ProductSubgroupCatalogPage from '@/models/entities/product-subgroup-catalog-page';
import ProductCatalogSpecLabel from '@/models/entities/product-catalog-spec-label';
import ProductStandardSpecLabel from '@/models/entities/product-standard-spec-label';
import ProductProductionSpecLabel from '@/models/entities/product-production-spec-label';
import Product from '@/models/entities/product';

class ProductSubgroup {
    product_subgroup_id;
    product_group;
    product_subgroup_name;
    priority;
    is_on_catalog;
    is_price_on_catalog;
    images;
    catalog_drawings;
    standard_drawings;
    production_drawings;
    catalog_pages;
    catalog_spec_labels;
    standard_spec_labels;
    production_spec_labels;
    products;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.product_subgroup_id = properties.product_subgroup_id ?? null;
        this.product_group = new ProductGroup(properties.product_group);
        this.product_subgroup_name = properties.product_subgroup_name ?? null;
        this.priority = properties.priority ?? null;
        this.is_on_catalog = properties.is_on_catalog ?? 0;
        this.is_price_on_catalog = properties.is_price_on_catalog ?? 0;

        this.images = [];
        if ('images' in properties) {
            for (let image of properties.images) {
                this.images.push(new ProductSubgroupImage(image));
            }
        }
        this.catalog_drawings = [];
        if ('catalog_drawings' in properties) {
            for (let catalog_drawing of properties.catalog_drawings) {
                this.catalog_drawings.push(new ProductSubgroupCatalogDrawing(catalog_drawing));
            }
        }
        this.standard_drawings = [];
        if ('standard_drawings' in properties) {
            for (let standard_drawing of properties.standard_drawings) {
                this.standard_drawings.push(new ProductSubgroupStandardDrawing(standard_drawing));
            }
        }
        this.production_drawings = [];
        if ('production_drawings' in properties) {
            for (let production_drawing of properties.production_drawings) {
                this.production_drawings.push(new ProductSubgroupProductionDrawing(production_drawing));
            }
        }
        this.catalog_pages = [];
        if ('catalog_pages' in properties) {
            for (let catalog_page of properties.catalog_pages) {
                this.catalog_pages.push(new ProductSubgroupCatalogPage(catalog_page));
            }
        }
        this.catalog_spec_labels = [];
        if ('catalog_spec_labels' in properties) {
            for (let catalog_spec_label of properties.catalog_spec_labels) {
                this.catalog_spec_labels.push(new ProductCatalogSpecLabel(catalog_spec_label));
            }
        }
        this.standard_spec_labels = [];
        if ('standard_spec_labels' in properties) {
            for (let standard_spec_label of properties.standard_spec_labels) {
                this.standard_spec_labels.push(new ProductStandardSpecLabel(standard_spec_label));
            }
        }
        this.production_spec_labels = [];
        if ('production_spec_labels' in properties) {
            for (let production_spec_label of properties.production_spec_labels) {
                this.production_spec_labels.push(new ProductProductionSpecLabel(production_spec_label));
            }
        }
        this.products = [];
        if ('products' in properties) {
            for (let product of properties.products) {
                this.products.push(new Product(product));
            }
        }
    }
}

export default ProductSubgroup;
