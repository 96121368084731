import Enum from './enum'

/**
 * ユーザ ログイン可否
 */
class IsAvailable extends Enum {
    static YES = 1; // 可
    static NO = 0; // 不可

    static values() {
        return {
            [this.YES]: '可',
            [this.NO]: '不可',
        }
    }
}

export default IsAvailable;
