import Enum from './enum'

/**
 * カタログ掲載フラグ
 */
class IsOnCatalog extends Enum {
    static YES = 1; // 掲載
    static NO = 0; // 非掲載

    static values() {
        return {
            [this.YES]: '掲載',
            [this.NO]: '非掲載',
        }
    }
}

export default IsOnCatalog;
