/**
 * 製品グループカタログ掲載マスタ エンティティ
 */
import { isNud } from '@/utilities/typing';
import ProductGroup from '@/models/entities/product-group';
import Catalog from '@/models/entities/catalog';

class ProductGroupCatalogPage {
    product_group_catalog_page_id;
    product_group;
    catalog;
    chapter;
    section;
    sequence;
    page_number;
    catalog_url;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.product_group_catalog_page_id = properties.product_group_catalog_page_id ?? null;
        this.product_group = new ProductGroup(properties.product_group);
        this.catalog = new Catalog(properties.catalog);
        this.chapter = properties.chapter ?? null;
        this.section = properties.section ?? null;
        this.sequence = properties.sequence ?? null;
        this.page_number = properties.page_number ?? null;
        this.catalog_url = properties.catalog_url ?? null;
    }

    get page_display() {
        let chapter = '';
        let section = '';
        let sequence = '';
        let page_number = '';

        if (!isNud(this.chapter)) {
            chapter = this.chapter + '章';
        }
        if (!isNud(this.section)) {
            section = this.section + '節';
        }
        if (!isNud(this.sequence)) {
            sequence = this.sequence + '項';
        }
        if (!isNud(this.page_number)) {
            page_number = `(${this.page_number})`;
        }

        return `${chapter}${section}${sequence}${page_number}`;
    }
}

export default ProductGroupCatalogPage;
