import { isNud } from '@/utilities/typing';
import Availability from '@/models/enums/availability';
import IsEachTime from '@/models/enums/is-each-time';
import IsOnCatalog from '@/models/enums/is-on-catalog';
import IsPriceOnCatalog from '@/models/enums/is-on-catalog';
import IsRetailerSelling from '@/models/enums/is-retailer-selling';
import ProductionType from '@/models/enums/production-type';
import PublicType from '@/models/enums/public-type';
import PublishedStatus from '@/models/enums/published-status';
import SupplyType from '@/models/enums/supply-type';

/**
 * 値を表示用に変換サービス・・・EnumをLabelに変換・３桁区切り等（entityも変換）
 * 
 * 製品詳細／製品編集履歴 で使用
 */
class DisplayValueService
{
    //keyをもとにenum値の場合はvalueを変換して返す
    static get(key, value) {
        if (isNud(key) || isNud(value)) {
            return null;
        }

        //公開区分
        if (key === 'public_type') {
            return PublicType.get(value);
        }

        //編集ステータス
        if (key === 'published_status') {
            return PublishedStatus.get(value);
        }

        //ステータス
        if (key === 'availability') {
            return Availability.get(value);
        }

        //仕入区分
        //販売価格
        if (key === 'supply_type') {
            return SupplyType.get(value);
        }

        //販売価格
        if (key === 'regular_price') {
            return value.toLocaleString(); //３桁区切り
        }

        //生産区分
        if (key === 'production_type') {
            return ProductionType.get(value);
        }

        //都度見積フラグ
        if (key === 'is_each_time') {
            return IsEachTime.get(value);
        }

        //カタログ掲載フラグ
        if (key === 'is_on_catalog') {
            return IsOnCatalog.get(value);
        }

        //カタログ金額掲載フラグ
        if (key === 'is_price_on_catalog') {
            return IsPriceOnCatalog.get(value);
        }

        //原価
        if (key === 'production_cost') {
            return value.toLocaleString(); //３桁区切り
        }
        //PB該当数量
        if (key === 'product_buffer_limit') {
            return value.toLocaleString(); //３桁区切り
        }

        //代理店価格
        if (key === 'distributor_price') {
            return value.toLocaleString(); //３桁区切り
        }

        //指定代理店価格
        if (key === 'designated_distributor_price') {
            return value.toLocaleString(); //３桁区切り
        }

        //グループ会社価格
        if (key === 'group_company_price') {
            return value.toLocaleString(); //３桁区切り
        }

        //工場出し価格
        if (key === 'shipping_price') {
            return value.toLocaleString(); //３桁区切り
        }

        //工場価格
        if (key === 'factory_price') {
            return value.toLocaleString(); //３桁区切り
        }
        
        //商社販売フラグ
        if (key === 'is_retailer_selling') {
            return IsRetailerSelling.get(value);
        }

        //商社別価格
        if (key === 'retailer_price') {
            return value.toLocaleString(); //３桁区切り
        }

        //掛率記号（enum ではなく entity から取得）
        if (key === 'wholesale_rate_id') {
            if (isNud(value) || isNud(value.rate_mark)) {
                return null;
            }

            return value.rate_mark;
        }

        return value;
    }
}

export default DisplayValueService;