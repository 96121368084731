import Enum from './enum'

/**
 * 都度見積フラグ
 */
class IsEachTime extends Enum {
    static YES = 1; // 〇（有効）
    static NO = 0; // -（無効）

    static values() {
        return {
            [this.YES]: '〇',
            [this.NO]: '-',
        }
    }
}

export default IsEachTime;
