/**
 * 掛率マスタ エンティティ
 */
import { isNud } from '@/utilities/typing'

class WholesaleRate {
    wholesale_rate_id;
    rate_mark;                   //掛率記号
    distributor_rate;            //代理店掛率
    designated_distributor_rate; //指定代理店掛率
    group_company_rate;          //グループ会社掛率
    shipping_rate;               //工場出し掛率
    factory_rate;                //工場掛率

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.wholesale_rate_id = properties.wholesale_rate_id;
        this.rate_mark = properties.rate_mark;
        this.distributor_rate = properties.distributor_rate;
        this.designated_distributor_rate = properties.designated_distributor_rate;
        this.group_company_rate = properties.group_company_rate;
        this.shipping_rate = properties.shipping_rate;
        this.factory_rate = properties.factory_rate;
    }
}

export default WholesaleRate;
