/**
 * 製品カタログ図用規格値 エンティティ
 */
import { isNud } from '@/utilities/typing';
import Product from '@/models/entities/product';
import ProductCatalogSpecLabel from '@/models/entities/product-catalog-spec-label';

class ProductCatalogSpecValue {
    product_catalog_spec_value_id;
    product_catalog_spec_value_revision_id;
    product;
    catalog_spec_label;
    spec_value;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        //（製品詳細画面で使用）リビジョンが来る場合があるため
        if (properties.product_catalog_spec_value_revision_id) {
            this.product_catalog_spec_value_revision_id = properties.product_catalog_spec_value_revision_id ?? null;
        } else {
            this.product_catalog_spec_value_id = properties.product_catalog_spec_value_id ?? null;
        }
        this.product = new Product(properties.product);
        this.catalog_spec_label = new ProductCatalogSpecLabel(properties.catalog_spec_label);
        this.spec_value = properties.spec_value ?? null;
    }
}

export default ProductCatalogSpecValue;
