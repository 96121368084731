import Enum from './enum'

/**
 * 製品ステータス
 */
class Availability extends Enum {
    static EXCEPT = -1; // 廃盤を除く
    static NORMAL = 1; // 正常
    static TEMPORARY_INFO = 2; // 暫定情報 (受注可)
    static TEMPORARY_PAUSE = 3; // 一時休止 (受注不可)
    static OUT_OF_PRINT = 9; // 廃番

    //一覧検索のselect値
    static values() {
        return {
            [this.EXCEPT]: '廃盤を除く',
            [this.NORMAL]: '正常',
            [this.TEMPORARY_INFO]: '暫定情報 (受注可)',
            [this.TEMPORARY_PAUSE]: '一時休止 (受注不可)',
            [this.OUT_OF_PRINT]: '廃番',
        }
    }

    //入力側のselect値
    static input_values() {
        return {
            [this.NORMAL]: '正常',
            [this.TEMPORARY_INFO]: '暫定情報 (受注可)',
            [this.TEMPORARY_PAUSE]: '一時休止 (受注不可)',
            [this.OUT_OF_PRINT]: '廃番',
        }
    }

    //表示時の略称
    static short_values() {
        return {
            [this.NORMAL]: '正常',
            [this.TEMPORARY_INFO]: '暫定',
            [this.TEMPORARY_PAUSE]: '休止',
            [this.OUT_OF_PRINT]: '廃番',
        }
    }

    static getShort(key) {
        const values = this.short_values();

        if (key in values) {
            return values[key];
        }

        return null;
    }

    static inputOptions() {
        let object = this.input_values();
        let array = [];
        let keys = Object.keys(object);
        keys.forEach((key) => {
            array.push({
                key: key,
                label: object[key],
            });
        });

        return array;
    }
}

export default Availability;
