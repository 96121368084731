import Enum from './enum'

/**
 * 製品一覧 編集ステータス
 */
class PublishedStatus extends Enum {
    static DRAFT = 0; // 下書きのみ
    static PUBLISHED = 1; // 公開中
    static HAS_REVISION = 2; // 公開中 (下書き有)

    static values() {
        return {
            [this.DRAFT]: '下書きのみ',
            [this.PUBLISHED]: '公開中',
            [this.HAS_REVISION]: '公開中 (下書き有)',
        }
    }
}

export default PublishedStatus;
