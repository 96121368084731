import Enum from './enum'

/**
 * 規格項目 入力タイプ
 */
class InputType extends Enum {
    static STRING = 10; // 文字列
    static INTEGER = 20; // 整数
    static DECIMAL_1 = 21; // 数値(少数1位)
    static DECIMAL_2 = 22; // 数値(少数2位)

    static values() {
        return {
            [this.STRING]: '文字列',
            [this.INTEGER]: '整数',
            [this.DECIMAL_1]: '数値(少数1位)',
            [this.DECIMAL_2]: '数値(少数2位)',
        }
    }
}

export default InputType;
