/**
 * 製品規格図用規格値 エンティティ
 */
import { isNud } from '@/utilities/typing';
import ProductRevision from '@/models/entities/product';
import ProductStandardSpecLabel from '@/models/entities/product-standard-spec-label';

class ProductStandardSpecValue {
    product_standard_spec_value_id;
    product_standard_spec_value_revision_id;
    product;
    standard_spec_label;
    spec_value;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        //（製品詳細画面で使用）リビジョンが来る場合があるため
        if (properties.product_standard_spec_value_revision_id) {
            this.product_standard_spec_value_revision_id = properties.product_standard_spec_value_revision_id ?? null;
        } else {
            this.product_standard_spec_value_id = properties.product_standard_spec_value_id ?? null;
        }
        this.product = new ProductRevision(properties.product);
        this.standard_spec_label = new ProductStandardSpecLabel(properties.standard_spec_label);
        this.spec_value = properties.spec_value ?? null;
    }
}

export default ProductStandardSpecValue;
