import Enum from './enum'

/**
 * 生産区分
 */
class ProductionType extends Enum {
    static ORDER = 1; // 受注生産
    static PURCHASE = 2; // 他社生産
    static STOCK = 3; // 在庫

    static values() {
        return {
            [this.ORDER]: '受注生産',
            [this.PURCHASE]: '他社生産',
            [this.STOCK]: '在庫',
        }
    }
}

export default ProductionType;