/**
 * 製品グループ動画マスタ エンティティ
 */
import { isNud } from '@/utilities/typing';
import ProductGroup from '@/models/entities/product-group';

class ProductGroupMovie {
    product_group_movie_id;
    product_group;
    movie_name;
    movie_url;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.product_group_movie_id = properties.product_group_movie_id ?? null;
        this.product_group = new ProductGroup(properties.product_group);
        this.movie_name = properties.movie_name ?? null;
        this.movie_url = properties.movie_url ?? null;
    }
}

export default ProductGroupMovie;
